<template>
    <div id="app">

        <div id="very-secure"  v-show="!verySecureSystem">
            <div class="flex" style="height: 3em">
                <input type="password" v-model="verySecure" placeholder="owo" style="width: 100%">
                <button style="width: 20%" v-on:click="secure">let me in</button>
            </div>

<!--            <p v-show="!verySecureSystem" style="margin-top: 30px; color: red;">{{verySecure}}</p>-->

        </div>

        <div class="options-area" v-show="verySecureSystem">
            <div v-on:click="importPreset" class="toggle-button">
                <span v-show="!imported">This was totally a good use of my time</span>
                <span v-show="imported">299 cards wtf</span>
            </div>

<!--            <div class="import-export-options" v-show="importExportVisible">-->
<!--                <button v-on:click="importPreset">Import preset lol</button>-->

<!--                <div class="option-field">-->
<!--                    <textarea v-model="textCardData" placeholder="Text stuff split by '|'"></textarea>-->
<!--                    <button v-on:click="addCardsFromText">Add Deck from Text</button>-->
<!--                </div>-->

<!--                <div class="option-field">-->
<!--                    <textarea v-model="objectCardData" placeholder="Object string here lol"></textarea>-->
<!--                    <button v-on:click="addCardsFromObject">Add Deck from Object</button>-->
<!--                </div>-->

<!--            </div>-->
            <p v-show="showDisclaimer" v-on:click="showDisclaimer = !showDisclaimer" style="cursor: pointer">disclaimer don't just take the answers without questioning them cos i mean idk if they're actually good lol i just did it for revision (click to hide)</p>

        </div>

        <div class="deck-display flex" v-show="verySecureSystem">
            <Deck v-for="deck in cardDecks" v-bind:card-objects="deck.cards" v-bind:key="deck.key" v-bind:id="deck.key"></Deck>
        </div>

        <p style="bottom:0; position:absolute; right:0;">Team 800 best team</p>
    </div>
</template>

<script>
    import Deck from "@/components/Deck";
    import {human, intro, design, evaluation} from "../public/hci/hci";

    export default {
        name: 'App',
        components: {
            Deck,
        },
        data() {
            return {
                textCardData: "",
                objectCardData: "",
                cardObjects: [],
                importExportVisible: false,

                cardDecks: [],

                deckHighestKey: -1,

                verySecure: "",
                verySecureSystem: true,

                imported: false,

                showDisclaimer: true,
            }
        },

        mounted() {
            this.importPreset();
            console.warn("gl for exam")
        },

        methods: {

            importPreset() {

                if (!this.imported) {
                    this.imported = true;
                    let decks = [intro, human, design, evaluation];
                    for (let i = 0; i < decks.length; i++) {
                        this.deckHighestKey++;
                        this.cardDecks.push({
                            key: this.deckHighestKey,
                            cards: decks[i]
                        })
                    }

                }
            },

            toggleImportExport() {
                this.importExportVisible = !this.importExportVisible;
            },

            /**
             * For the copy paste from excel
             */
            addCardsFromText() {
                let cardDataSplit = this.textCardData.split("|");

                let newDeck = {};

                let i = 0;
                let cardId = 0;
                while (i < cardDataSplit.length) {
                    newDeck[cardId] = {
                        id: cardId,
                        category: cardDataSplit[i].trim(),
                        subcategory: cardDataSplit[i + 1].trim(),
                        question: cardDataSplit[i + 2],
                        answer: cardDataSplit[i + 3],
                        keywordsList: []
                    };
                    i += 4;
                    cardId++;
                }

                this.deckHighestKey++;
                this.cardDecks.push({
                    key: this.deckHighestKey,
                    cards: newDeck
                })


            },


            /**
             * For copy paste from export deck
             */
            addCardsFromObject() {
                let newDeck = JSON.parse(this.objectCardData);

                this.deckHighestKey++;
                this.cardDecks.push({
                    key: this.deckHighestKey,
                    cards: newDeck
                })

            },

            secure() {
                if (this.verySecure === "let me in") { // hey what are you looking at this for this is super secret wtffff jk
                    this.verySecureSystem = true;
                }
            }
        },
    }
</script>

<style>
    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
    }

    * {
        margin: 0;
    }

    .deck-display {
    }

    body {
        background-color: #17141d;
        color: white;
    }

    .options-area {
        width: 50%;
        margin: auto;
        text-align: center;
    }

    .flex {
        display: flex;
    }

    .import-export-options {
        display: flex;
    }

    .option-field {
        display: flex;
        flex-direction: column;
    }

    .toggle-button {
        margin-top: 1em;
        padding: 1em;
        background-color: #322b3d;
        color: white;
        border-radius: 25px;
        text-align: center;
        cursor: pointer;
    }

    #very-secure {
        width: 50%;
        margin: 3em auto auto;
        height: 3em;
    }
</style>
