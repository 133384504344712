<template>
    <div class="card-container mobile-card-container">

        <p class="press-shuffle" v-show="questionText === ''">Press shuffle lol</p>

        <section class="card-category">
            <h3 class="pb-2"><span v-show="questionText !== ''">{{visibleCardId}}:</span> {{category}}</h3>
            <p>{{subcategory}}</p>
        </section>
        <section class="card-question">
            <div class="pb-2">
                <h4 class="pb-2" v-show="questionText !== ''">Question</h4>
                <p >{{questionText}}</p>
            </div>
            <div v-show="answerVisible && !editAnswerModeActive">
                <h4 class="pb-2">Answer</h4>
                <span v-html="visibleAnswer"></span>
            </div>

            <div v-show="editAnswerModeActive">
                <textarea style="width: 98%; height: 200px" v-model="answer"></textarea>
                <div class="card-button" v-on:click="saveAnswer">
                    Save answer
                </div>
            </div>

        </section>
        <div class="card-buttons">

            <div id="answer-button" class="card-button" v-on:click="toggleAnswer">Answer</div>

            <div class="flex" style="justify-content: space-evenly">
                <div class="card-button" style="width: 100%" v-on:click="backCard">Back</div>
                <div class="card-button" style="width: 100%; margin-left: 1em; margin-right: 1em;" v-on:click="shuffleDeck">Shuffle</div>
                <div class="card-button" style="width: 100%" v-on:click="nextCard">Next</div>
            </div>


            <div class="flex mt-2" style="justify-content: space-evenly">
                <div class="card-button" v-on:click="toggleDeckOptions">
                    <span v-show="!deckOptionsVisible">Show</span>
                    <span v-show="deckOptionsVisible">Hide</span>
                    Filter Options
                </div>

<!--                <div class="card-button" v-on:click="toggleKeywordOptions">-->
<!--                    <span v-show="!keywordOptionsVisible">Show</span>-->
<!--                    <span v-show="keywordOptionsVisible">Hide</span>-->
<!--                    Edit Options-->
<!--                </div>-->

            </div>

            <div class="options-div mt-4" v-show="deckOptionsVisible">
                <div class="flex pb-2">
                    <div class="mr-2">
                        <p class="pb-2">Category</p>
                        <select class="card-filter" v-model="selectedCategory">
                            <option v-bind:key="categoryOpt" v-for="categoryOpt in categoryFilters">{{categoryOpt}}</option>
                        </select>
                    </div>

                    <div>
                        <p class="pb-2">Subcategory</p>
                        <select class="card-filter" v-model="selectedSubcategory">
                            <option v-bind:key="subcategoryOpt" v-for="subcategoryOpt in subcategoryFilters">{{subcategoryOpt}}</option>
                        </select>
                    </div>
                </div>

                <p class="pb-2">Ah yes</p>
                <div class="card-button mr-2" v-on:click="exportDeck">Export deck</div>
            </div>

            <div class="options-div mt-4" v-show="keywordOptionsVisible">

                <div class="card-button" v-on:click="editAnswerMode" v-show="!editAnswerModeActive">Edit answer</div>

                <p class="pb-2">Answer keywords (comma separated)</p>
                <textarea id="keyword-textarea" v-model="keywordsString"></textarea>

                <div class="card-button mr-2" v-on:click="saveKeywords">Save keywords</div>
            </div>



        </div>


    </div>
</template>

<script>
    export default {
        name: "Flashcards",
        props: {
            cardObjects: {
                type: Object,
                default: () => {}
            },

        },

        data() {
            return {
                visibleCardId: 0,
                category: "",
                subcategory: "",
                questionText: "",
                answer: "",
                keywordsList: [],
                answerVisible: false,
                deckOptionsVisible: false,
                keywordOptionsVisible: false,

                selectedCategory: "All",
                selectedSubcategory: "All",

                filteredCardObjects: this.cardObjects,

                keywordsString: "",

                editAnswerModeActive: false,
            }
        },

        watch: {
            cardObjects() {
                this.updateInfo();
            },

            selectedSubcategory() {
                this.filterDeck();
            },

            selectedCategory() {
                this.selectedSubcategory = "All";
                this.filterDeck();
            },


        },

        computed: {

            visibleAnswer() {
                let displayAnswer = this.answer.trim();
                if (this.keywordsList) {
                    for (let i = 0; i < this.keywordsList.length; i++) {
                        displayAnswer = displayAnswer.replace(this.keywordsList[i].trim(), "<span style='color: goldenrod'>" + this.keywordsList[i].trim() + "</span>");
                    }
                }

                return displayAnswer;
            },

            categoryFilters() {
                let filters = ["All"];

                for (let i = 0; i < Object.keys(this.cardObjects).length; i++) {
                    let cat = this.cardObjects[i].category;
                    if (!filters.includes(cat)) {
                        filters.push(cat);
                    }
                }

                return filters;
            },

            subcategoryFilters() {
                let filters = ["All"];



                for (let i = 0; i < Object.keys(this.cardObjects).length; i++) {
                    if (this.selectedCategory === "All" || this.cardObjects[i].category === this.selectedCategory) {
                        let cat = this.cardObjects[i].subcategory;
                        if (!filters.includes(cat)) {
                            filters.push(cat);
                        }
                    }

                }

                return filters;
            }
        },


        methods: {


            filterDeck() {


                this.filteredCardObjects = {};

                let categoryFiltered = {};

                for (let i = 0; i < Object.keys(this.cardObjects).length; i++) {
                    let card = this.cardObjects[i];

                    if (this.selectedCategory === "All" || card.category === this.selectedCategory) {
                        categoryFiltered[card.id] = card;
                    }
                }

                let keys = Object.keys(categoryFiltered);

                for (let i = 0; i < keys.length; i++) {
                    let card = categoryFiltered[keys[i]];

                    if (this.selectedSubcategory === "All" || card.subcategory === this.selectedSubcategory) {
                        this.filteredCardObjects[card.id] = card;
                    }
                }
                this.visibleCardId = Object.keys(this.filteredCardObjects)[0]

                // console.table(this.filteredCardObjects)

                this.updateInfo();

            },

            backCard() {
                let ids = Object.keys(this.filteredCardObjects).sort((a, b) => a - b);
                let index = ids.indexOf(this.visibleCardId.toString());
                if (index - 1 < 0) {
                    this.visibleCardId = ids.length - 1;
                } else {
                    this.visibleCardId = ids[index - 1];
                }
                this.updateInfo();
            },

            nextCard() {

                let ids = Object.keys(this.filteredCardObjects).sort((a, b) => a - b);
                let index = ids.indexOf(this.visibleCardId.toString());
                if (index + 1 >= ids.length) {
                    this.visibleCardId = ids[0];
                } else {
                    this.visibleCardId = ids[index + 1];
                }
                this.updateInfo();

            },

            shuffleDeck() {

                let keys = Object.keys(this.filteredCardObjects);

                this.visibleCardId = keys[Math.floor(Math.random() * Object.keys(this.filteredCardObjects).length)];

                this.updateInfo();
            },

            updateInfo() {
                // this.id = this.filteredCardObjects[this.visibleCardId].id;
                this.category = this.filteredCardObjects[this.visibleCardId].category;
                this.subcategory = this.filteredCardObjects[this.visibleCardId].subcategory;
                this.questionText = this.filteredCardObjects[this.visibleCardId].question;
                this.answer = this.filteredCardObjects[this.visibleCardId].answer;
                this.keywordsList = this.filteredCardObjects[this.visibleCardId].keywordsList;
                if (this.keywordsList) {
                    this.keywordsString = this.keywordsList.concat();
                } else {
                    this.keywordsString = "";
                }
                this.answerVisible = false;
            },

            toggleAnswer() {
                this.answerVisible = !this.answerVisible;
            },

            exportDeck() {
                let exportWindow = window.open("", "Export Deck", "width=700px, height=500px");
                exportWindow.document.write(JSON.stringify(this.cardObjects));
            },

            toggleDeckOptions() {
                this.deckOptionsVisible = !this.deckOptionsVisible;
                this.keywordOptionsVisible = false;
            },


            toggleKeywordOptions() {
                this.keywordOptionsVisible = !this.keywordOptionsVisible;
                this.deckOptionsVisible = false;
            },

            saveKeywords() {
                if (typeof(this.keywordsString) === "string") {
                    console.warn("saving")
                    this.keywordsList = this.keywordsString.split(",");
                    this.filteredCardObjects[this.visibleCardId].keywordsList = this.keywordsList;
                    this.cardObjects[this.visibleCardId].keywordsList = this.keywordsList;
                }

            },

            editAnswerMode() {
                this.editAnswerModeActive = true;
            },

            saveAnswer() {
                this.editAnswerModeActive = false;
                this.cardObjects[this.visibleCardId].answer = this.answer;
            }

        },

    }
</script>

<style scoped>

    @media (min-width: 500px) {
        .card-container {
            min-width: 25em;
            width: 30em;
            min-height: 40em;
            /*margin: auto;*/
            margin: 1em;
            border-radius: 16px;
            background:  #17141d;
            box-shadow: -1rem 0 3rem black;
            color: lightgrey;
            position: relative;
        }
    }

    @media (max-width: 500px) {
        .card-container {
            min-width: 100vw;
            min-height: 40em;
            margin-top: 1em;
            /*margin: auto;*/
            /*margin: 1em;*/
            border-radius: 16px;
            background:  #17141d;
            box-shadow: -1rem 0 3rem black;
            color: lightgrey;
            position: relative;
        }
    }




    .card-container p {
        margin: 0;
    }


    .card-category {
        /*background-color: pink;*/
        padding: 1em;
        color: goldenrod;
    }

    .card-question {
        /*background-color: skyblue;*/
        padding: 1em;
        min-height: 20em;
    }

    .pb-2 {
        padding-bottom: 10px;
    }

    .mr-2 {
        margin-right: 10px;
    }

    .card-buttons {
        padding: 1em;
        position: absolute;
        bottom: 0;
        width: 94%;

    }

    .card-button {
        background-color: #322b3d;
        color: white;
        padding: 0.5em;
        margin-bottom: 0.5em;
        cursor: pointer;
        border-radius: 10px;
    }

    .float-right {
        float: right;
    }

    .card-filter {
        padding: 0.5em;
        margin-bottom: 0.5em;
        border-radius: 10px;
        min-width: 100px;
        max-width: 14em;

    }

    .options-div {
        position: absolute;
        max-width: 30em;

    }

    .mt-2 {
        margin-top: 20px;
    }

    .mt-4 {
        margin-top: 40px;
    }

    .press-shuffle {
        padding-top: 10em;
        text-align: center;
    }

    #answer-button {
        display: flex;
        height: 2rem;
        align-items: center;
    }

    .card-button:hover {
        background-color: #473e57;
    }

    #keyword-textarea {
        min-width: 25em;
        width: 30em;
        height: 3em;
    }

    .gold-text {
        color: goldenrod;
    }

</style>
